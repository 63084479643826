import React from 'react';
// import { Link } from 'react-router-dom';


const styles = {
    cloudWebsite: {
      minHeight: '100vh',
      // backgroundColor: '#e6f2ff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    heroSection: {
      position: 'relative',
      width: '100%',
      height: '384px',
      // background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '23px',
    },
    window: {
    marginTop: '120px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    // cloudIcon: {
    //   color: '#3b82f6',
    // },
    companyLogo: {
      // position: 'absolute',
      // top: '26%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      padding: '16px',
    },
    // companyLogoImg: {
    //   width: '535px',
    // },
    // companyWindowImg: {
    //   width: '80%',
    // },
    mottoSection: {
      textAlign: 'center',
      marginTop: '32px',
      padding: '0 16px',
    },
    mottoHeading: {
      fontSize: '1.80rem',
      fontWeight: 'bold',
      color: '#fff',
      paddingTop: '20px',
    },
    mottoParagraph: {
      marginTop: '8px',
      fontSize: '1.125rem',
      color: '#fff',
      margin: '0 20%',
    },
    cardsSection: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '48px',
      padding: '0 16px',
    //   flexDirection: 'row',
    //   "@media (maxWidth: 700px)": {
    //     flexDirection: 'column',
    //   }
    },
    card: {
      backgroundImage: 'linear-gradient(to bottom, white, #2c2037)',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      width: '256px',
      margin: '20px 16px',
    },
    cardHeading: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#fff'
    },
    cardParagraph: {
      marginTop: '8px',
      color: 'black',
    },
};


function Home() {
  return (
    <div style={styles.cloudWebsite}>
        <div style={styles.heroSection}>
            <div style={styles.companyLogo}>
                <img src="/ata.png" alt="Company Logo" className='main-logo' />
            </div>
            
        </div>

        <div style={styles.mottoSection}>
            <h1 style={styles.mottoHeading}>Icy Delights</h1>
            <p style={styles.mottoParagraph}>
              Savor the excitement of indulgence with our unique icy delights and other accessories. 
              We're passionate about bringing you the best brands and flavors. 
              Our team searches far and wide to curate the most unique and delicious products for you to enjoy. 
              From classic treats to innovative creations, we're constantly on the lookout for the next illustrious thing. 
            </p>
        </div>

        <div style={styles.cardsSection} className='cardsec'>
            <div style={styles.card}>
                <h2 style={styles.cardHeading}>Delightful Ice</h2>
                <p style={styles.cardParagraph}>New Interactions (<i style={{color: '#b4a0d2'}}>Coming Soon</i>)</p>
            </div>

            <div style={styles.card}>
              <h2 style={styles.cardHeading}>Events</h2>
              <p style={styles.cardParagraph}>Find the Fun (<i style={{color: '#b4a0d2'}}>Coming Soon</i>)</p>
            </div>

            <div style={styles.card}>
                <h2 style={styles.cardHeading}>Store</h2>
                <p style={styles.cardParagraph}>New Offerings (<i style={{color: '#b4a0d2'}}>Coming Soon</i>)</p>
            </div>
        </div>
    </div>
  );
}

export default Home;